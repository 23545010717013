import React from "react"

import { Cross, Plus, Edit, TrashCan } from "akar-icons"

import { ADD_ADDRESS, UPDATE_ADDRESS } from "../../constants/general-constants"
import {
  accountAddressCreate,
  accountAddressDelete,
  accountAddressUpdate,
  getListOfAddress,
} from "../../graphql/checkout"
import { AuthContext } from "../../store/auth"
import Loader from "../common/Loader"
import { TYPE_ERROR, toastMessage, TYPE_SUCCESS } from "../common/Toast"
import AddressCard from "./AddressCard"
import "./ManageAddress.scss"

const initFormData = {
  city: "",
  country: "IN",
  countryArea: "",
  firstName: "",
  lastName: "",
  postalCode: "",
  phone: "",
  streetAddress1: "",
  streetAddress2: "",
}

const ManageAddress = () => {
  const { user } = React.useContext(AuthContext)
  const [savedAddressList, setSavedAddressList] = React.useState([])
  const [addressToEdit, setAddressToEdit] = React.useState(initFormData)

  const [isEditFormVisible, setIsEditFormVisible] = React.useState(false)
  const [isAddNew, setIsAddNew] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const updateSavedAddressState = (token) => {
    setIsLoading(true)
    getListOfAddress(token)
      .then((res) => {
        if (res.me === null) {
          toastMessage("Invalid Token", TYPE_ERROR)
        } else {
          setSavedAddressList(res.me.addresses)
          setIsLoading(false)
        }
      })
      .catch(() => {
        toastMessage("Something Went Wrong", TYPE_ERROR)
        setIsLoading(false)
      })
  }

  React.useEffect(() => {
    if (user.token) {
      updateSavedAddressState(user.token)
    }
  }, [user])

  const handleAddNewAddressModal = () => {
    setAddressToEdit(initFormData)
    setIsAddNew(true)
    setIsEditFormVisible(true)
  }

  const handleAddressFormChange = (e) => {
    const value =
      e.target.name === "phone" && e.target.value < 0
        ? Math.abs(e.target.value)
        : e.target.value

    setAddressToEdit({
      ...addressToEdit,
      country: "IN",
      [e.target.name]: value,
    })
  }

  const deleteAddress = (id) => {
    accountAddressDelete(id, user.token)
      .then((res) => {
        if (res.accountAddressDelete.accountErrors.length > 0) {
          toastMessage(
            res.accountAddressDelete.accountErrors[0].message,
            TYPE_ERROR
          )
        } else {
          toastMessage("Address Deleted Successfully", TYPE_SUCCESS)
          updateSavedAddressState(user.token)
        }
      })
      .catch(() => {
        toastMessage("Something Went wrong", TYPE_ERROR)
      })
  }

  const handleEditDeleteButtonClick = (id, type) => {
    if (type === "delete") {
      deleteAddress(id)
    } else {
      const current = savedAddressList.find((el) => el.id === id)
      setAddressToEdit({
        city: current.city,
        country: "IN",
        countryArea: current.countryArea,
        id: current.id,
        firstName: current.firstName,
        lastName: current.lastName,
        postalCode: current.postalCode,
        phone: current.phone.substring(3, current.phone.length),
        streetAddress1: current.streetAddress1,
        streetAddress2: current.streetAddress2,
      })
      setIsEditFormVisible(true)
    }
  }

  const formSubmitHandler = () => {
    if (isAddNew) {
      accountAddressCreate(addressToEdit, "SHIPPING", user.token)
        .then((res) => {
          if (res.accountAddressCreate.accountErrors.length > 0) {
            toastMessage(
              res.accountAddressCreate.accountErrors[0].message,
              TYPE_ERROR
            )
          } else {
            updateSavedAddressState(user.token)
            setIsAddNew(false)
            toastMessage("New Address Added Successfully", TYPE_SUCCESS)
            setIsEditFormVisible(false)
          }
        })
        .catch(() => {
          toastMessage("Something Went wrong", TYPE_ERROR)
          setIsAddNew(false)
          setIsEditFormVisible(false)
        })
    } else {
      const copyAddressInp = { ...addressToEdit }
      delete copyAddressInp.id
      accountAddressUpdate(copyAddressInp, addressToEdit.id, user.token)
        .then((res) => {
          if (res.accountAddressUpdate.accountErrors.length > 0) {
            toastMessage(
              res.accountAddressUpdate.accountErrors[0].message,
              TYPE_ERROR
            )
          } else {
            updateSavedAddressState(user.token)
            setIsAddNew(false)
            toastMessage("Address Updated Successfully", TYPE_SUCCESS)
            setIsEditFormVisible(false)
          }
        })
        .catch(() => {
          toastMessage("Something Went wrong", TYPE_ERROR)
          setIsEditFormVisible(false)
        })
    }
  }
  const closeFormModal = () => {
    setIsAddNew(false)
    setIsEditFormVisible(false)
  }
  if (isLoading) {
    return <Loader />
  }
  return (
    <section className="manage-address">
      <div className="manage-address__container">
        <div className="manage-address__container__top-section">
          <div className="manage-address__container__top-section__title">
            Manage Saved Addresses
          </div>
          <div className="manage-address__container__top-section__add-new">
            <button
              className="manage-address__container__top-section__add-new__button"
              onClick={handleAddNewAddressModal}
            >
              Add New Address &nbsp;
              <Plus strokeWidth={2} size={20} />
            </button>
          </div>
        </div>
        {savedAddressList.length > 0 ? (
          <div className="manage-address__container__card-wrapper">
            {savedAddressList.map((add) => (
              <div
                key={add.id}
                className="manage-address__container__card-wrapper__card"
              >
                <div className="manage-address__container__card-wrapper__card__description">
                  <p>
                    <strong>Name:</strong> {add.firstName} {add.lastName},
                  </p>
                  <p>
                    <strong>Address:</strong> {add.streetAddress1}{" "}
                    {add.streetAddress2} {add.city} {add.postalCode}{" "}
                    {add.countryArea}.
                  </p>
                  <p>
                    <strong>Phone Number:</strong> {add.phone}
                  </p>
                </div>
                <div className="manage-address__container__card-wrapper__card__button">
                  <button
                    className="manage-address__container__card-wrapper__card__button__btn"
                    onClick={() => handleEditDeleteButtonClick(add.id, "edit")}
                  >
                    <Edit
                      strokeWidth={2}
                      size={24}
                      color="var(--color-primary)"
                    />
                  </button>
                  <button
                    onClick={() =>
                      handleEditDeleteButtonClick(add.id, "delete")
                    }
                    className="manage-address__container__card-wrapper__card__button__btn"
                  >
                    <TrashCan strokeWidth={2} size={24} color="red" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="manage-address__container__no-result">
            No Saved Address Yet
          </div>
        )}
      </div>
      {isEditFormVisible && (
        <div className="manage-address__edit-address">
          <div
            className="manage-address__edit-address__background"
            onClick={closeFormModal}
            onKeyDown={(e) => {
              if (e.key === "Enter") closeFormModal()
            }}
            tabIndex={0}
            role="button"
          ></div>
          <div className="manage-address__edit-address__form">
            <div
              className="manage-address__edit-address__form__close"
              onClick={closeFormModal}
              onKeyDown={(e) => {
                if (e.key === "Enter") closeFormModal()
              }}
              tabIndex={0}
              role="button"
            >
              <Cross />
            </div>
            <AddressCard
              address={addressToEdit}
              handleChange={handleAddressFormChange}
              type={isAddNew ? ADD_ADDRESS : UPDATE_ADDRESS}
            />
            <button
              onClick={formSubmitHandler}
              className="manage-address__edit-address__form__button"
            >
              {isAddNew ? "Add" : "Update"}
            </button>
          </div>
        </div>
      )}
    </section>
  )
}

export default ManageAddress

import React from "react"

import { navigate } from "gatsby"

import ManageAddress from "../components/checkout/ManageAddress"
import SEO from "../components/seo"

const ManageAddressPage = () => {
  React.useEffect(() => {
    if (!localStorage.token) {
      navigate("/login")
    }
  })
  return (
    <>
      <SEO title="ManageAddress" path="/manage-address" />
      <ManageAddress />
    </>
  )
}

export default ManageAddressPage
